<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-no-padding">
            <h5 class="ion-padding" v-if="appInstances.length === 0">{{ language.noAppInstances }}</h5>
            <ion-item lines="full" mode="md" v-for="appInstance of appInstances" :key="appInstance.id" @click="editAppInstance(appInstance.id)">
                <ion-label>
                    <h2>{{ appInstance.name }}<span v-if="appInstance.incomplete"> - {{ language.incomplete }}</span></h2>
                    <p>{{ appInstance.partner.name }}</p>
                </ion-label>
                <DirectionIndicator class="edit-forward" :is-ltr-forward="true" />
            </ion-item>
        </ion-content>
        <FixedFooter :buttons="footerButtons"/>
    </ion-page>
</template>

<script lang="ts">

import {IonContent, IonItem, IonPage} from "@ionic/vue";
import router from "@/router";
import {computed, ref} from "vue";
import { AppInstance } from '@/models/AppInstanceModel';
import FixedFooter from '@/components/FixedFooter.vue'
import { execute } from "@/mixins/LoadingMixin";
import {getAllAppInstances} from "@/services/AppInstanceService";
import {useStore} from "vuex";
import {defineComponent} from "@vue/runtime-core";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default defineComponent({
    name: 'AppInstances',
    components: {
        IonContent,
        IonPage,
        IonItem,
        FixedFooter,
        DirectionIndicator
    },
    async ionViewWillEnter() {
        await execute( async () => {
            this.appInstances = await getAllAppInstances();
        }, this.language.errors.gettingAppInstances);
        await this.store.dispatch('app/setPageTitle', this.language.appInstance);
    },
    setup() {
        const store = useStore();
        const appInstances = ref([] as AppInstance[]);
        const language = computed(() => store.getters['app/language']);
        
        const editAppInstance = async (id: string) => {
            router.push({ name: 'editAppInstance', params: { id: id } });
        }

        const addAppInstance = async () => {
            router.push({ name: 'addAppInstance' });
        }

        const footerButtons = [{
            class: 'custom-button button-clear',
            icon: false,
            iconPos: '',
            handler: () => addAppInstance(),
            text: language.value.addAppInstance
        }];

        return {
            appInstances,
            editAppInstance,
            addAppInstance,
            footerButtons,
            language,
            store
        }
    }
});
</script>

<style scoped>
h5 {
    text-align: center;
}
ion-label h2 span {
    color: red;
}
</style>